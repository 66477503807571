application.register("comp_highlights", class extends Stimulus.Controller {
    get target() {
        return {
            links: this.targets.findAll("link"),
            images: this.targets.findAll("image"),
        }
    }

    setActive(event) {
        if(window.innerWidth >= 960) {
            const index = event.target.dataset.actionIndex;

            this.target.links.forEach((link)=>{
                link.classList.remove("state--active");
            });
            this.target.images.forEach((image)=>{
                image.classList.remove("state--active");
            });

            this.target.images[index].classList.add("state--active");
            this.target.links[index].classList.add("state--active");
        }
    }
});