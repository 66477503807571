application.register("lib-masonry", class extends Stimulus.Controller {
    connect() {
        let self = this;

        self._ticking = false;
        self._msnry = "";

        importScript(cdnjs.masonry,function () {
            cssLoaded(function () {
                if (!Masonry.data(self.element)) {
                    self._msnry = new Masonry(self.element,{
                        itemSelector: "[data-lib-masonry-col]",
                        percentPosition: true,
                        columnWidth: "[data-lib-masonry-col]",
                        horizontalOrder: (window.innerWidth >= 960),
                        fitWidth: false
                    });
                } else {
                    self._msnry.appended(self.element.querySelectorAll(`[data-lib-masonry-col]:not([style])`));
                    self._msnry.layout();
                }
            });
        });
    }
});