window.lui_map_responses = {};

application.register("part_map", class extends Stimulus.Controller {

    get target() {
        return {
            map: this.targets.find("map"),
            infoWindow: this.targets.find("infoWindow"),
            controls: this.targets.find("controls")
        }
    }

    connect() {
        let self = this, options = JSON.parse(this.element.dataset.options),
            markersUrl = this.element.dataset.markers;

        inView(this.element, () => {
            importScript([cdnjs.googlemaps.replace("{apikey}", options["apikey"])], () => {
                let coords = options["center"].split(/,\s/);
                let bounds = new google.maps.LatLngBounds();
                let markers_temp = [];
                let center = new google.maps.LatLng(parseFloat(coords[0]), parseFloat(coords[1]));
                let info_template = "";

                let map = new google.maps.Map(self.target.map, {
                    center: center,
                    zoom: 14,
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: true,
                    styles: [
                        {
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#616161"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.land_parcel",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#bdbdbd"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#eeeeee"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#e5e5e5"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#dadada"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#616161"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.line",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#e5e5e5"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.station",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#eeeeee"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#c9c9c9"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        }
                    ]
                });

                let icon = {
                    url: '/assets/img/marker.svg',
                    scaledSize: new google.maps.Size(28, 35),
                    anchor: new google.maps.Point(14, 35)
                };

                let iconMain = {
                    url: '/assets/img/marker-main.svg',
                    scaledSize: new google.maps.Size(64, 79),
                    anchor: new google.maps.Point(32, 79)
                };

                if(typeof self.target.infoWindow !== "undefined") {
                    info_template = self.target.infoWindow.innerHTML;
                }

                let infoWindow = new google.maps.InfoWindow({
                    content: "",
                });

                let markerMain = new google.maps.Marker({
                    title: "Hotel CUBE",
                    map: map,
                    position: center,
                    icon: iconMain,
                });

                bounds.extend(center);
                markers_temp.push(markerMain);

                if(typeof markersUrl !== "undefined") {
                    function getMarkers(url,callback) {
                        if (typeof window.lui_map_responses[url] === "undefined") {
                            fetch(url, {headers: {'X-Requested-With': 'XMLHttpRequest'}}).then((response) => {
                                return response.json();
                            }).then((payload) => {
                                window.lui_map_responses[url] = payload;
                                callback(payload);
                            });
                        }
                        else {
                            callback(window.lui_map_responses[url]);
                        }
                    }

                    function generateMarker(item) {
                        let title = item["title"];
                        let point = new google.maps.LatLng(parseFloat(item["latitude"]), parseFloat(item["longitude"]));
                        let content = info_template
                            .replaceAll("*title*", title)
                            .replaceAll("*location*", item["location"])
                            .replaceAll("*text*", item["text"]);

                        let marker = new google.maps.Marker({
                            title: title,
                            map: map,
                            position: point,
                            icon: item["fitness"] ? iconFitness : icon,
                        });
                        bounds.extend(point);
                        markers_temp.push(marker);

                        function showInfo(marker) {
                            infoWindow.close();
                            infoWindow.setContent(content);
                            infoWindow.open(map, marker);
                        }

                        marker.addListener('click', () => {
                            showInfo(marker);
                        });

                        marker.addListener('mouseover', () => {
                            showInfo(marker);
                        });
                    }

                    map.addListener("click", () => {
                        infoWindow.close();
                    })

                    getMarkers(markersUrl, (payload) => {
                        bounds = new google.maps.LatLngBounds();

                        for (let item of payload) {
                            generateMarker(item);
                        }

                        if (payload.length > 1) {
                            // map.fitBounds(bounds);
                        } else {
                            map.setCenter({lat: payload[0].latitude, lng:payload[0].longitude});
                        }
                    });
                }

                if(typeof this.target.controls !== "undefined") {
                    let directionsService = new google.maps.DirectionsService;
                    let directionsDisplay = new google.maps.DirectionsRenderer({
                        polylineOptions: {
                            strokeColor: "#121722",
                            strokeWeight: "6"
                        },
                        markerOptions: {
                            icon: {
                                url: '/assets/img/marker.svg',
                                scaledSize: new google.maps.Size(28, 35),
                                anchor: new google.maps.Point(14, 35)
                            }
                        },
                        suppressInfoWindows: true,
                        suppressMarkers: true,
                    });

                    directionsDisplay.setMap(map);

                    this.target.controls.addEventListener("submit", (e) => {
                        e.preventDefault();
                        let frm = e.currentTarget;
                        let settings = {};
                        let settingsData = new FormData(frm);

                        for (let key of settingsData.entries()) {
                            settings[key[0]] = key[1];
                        }

                        directionsService.route({
                            origin: center,
                            destination: settings.destination,
                            travelMode: google.maps.TravelMode[settings.type]
                        }, (response, status) => {
                            if (status === 'OK') {
                                directionsDisplay.setDirections(response);
                            } else {
                                console.log('Directions request failed due to ' + status);
                            }
                        });
                    });
                }
            });
        })
    }
});