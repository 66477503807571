application.register("comp_visual_main", class extends Stimulus.Controller {
    scrollToNext() {
        let nextElementOffset = this.element.nextElementSibling.offsetTop;

        if(typeof locomotive !== "undefined" &&  !locomotive.scroll.isMobile) {
            locomotive.scrollTo(nextElementOffset,0);
        }
        else {
            document.documentElement.scroll({top: nextElementOffset, behavior: "smooth"});
        }
    }
});