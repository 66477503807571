application.register("layout_nav", class extends Stimulus.Controller {
    connect() {
        let links = [];
        Array.from(this.element.querySelectorAll(".elm_nav_inner")).forEach((nav)=>{
            Array.from(nav.querySelectorAll(".elm_nav_item")).forEach((link)=>{
                links.push(link);
            });
        })

        Array.from(links).forEach((link, index)=>{
            link.style.transitionDelay = `${0.2*(index+1)}s`;
        });
    }
    reset() {
        let navs = this.element.querySelectorAll(".elm_nav_inner");

        Array.from(navs).forEach((nav)=> {
            nav.classList.remove("state--changing","state--hidden");
        })
    }
});