const cdnjs = {
    "picturefill": "https://cdn.jsdelivr.net/npm/picturefill@3.0.3/dist/picturefill.min.js",
    "recaptcha": "https://www.google.com/recaptcha/api.js?render={apikey}",
    "seamless": "https://cdn.jsdelivr.net/npm/seamless-scroll-polyfill@1.0.9/dist/es5/seamless.min.js",
    "pickr": "https://cdn.jsdelivr.net/npm/@simonwep/pickr/dist/pickr.es5.min.js",
    "pickr_css": "https://cdn.jsdelivr.net/npm/@simonwep/pickr/dist/themes/nano.min.css",
    "cleave": "https://cdn.jsdelivr.net/npm/cleave.js@1.6.0/dist/cleave.min.js",
    "datepicker": "https://cdn.jsdelivr.net/npm/vanillajs-datepicker@1.0.3/dist/js/datepicker-full.min.js",
    "datepicker_i18n": "https://cdn.jsdelivr.net/npm/vanillajs-datepicker@1.0.3/dist/js/locales/cs.js",
    "masonry": "https://cdn.jsdelivr.net/npm/masonry-layout@4.2.2/dist/masonry.pkgd.min.js",
    "autosize": "https://cdn.jsdelivr.net/npm/autosize@4.0.2/dist/autosize.min.js",
    "googlemaps": "https://maps.googleapis.com/maps/api/js?key={apikey}",
    "popper": "https://cdn.jsdelivr.net/npm/@popperjs/core@2.6.0/dist/umd/popper.min.js",
    "tippy": "https://cdn.jsdelivr.net/npm/tippy.js@6.2.7/dist/tippy.umd.js",
    "tippy_css": "https://cdn.jsdelivr.net/combine/npm/tippy.js@6.2.7/animations/shift-away.min.css,npm/tippy.js@6.2.7/dist/tippy.min.css",
};
