application.register("layout_header", class extends Stimulus.Controller {
    connect() {
        this._currentScrollY = 0;
        this._latestKnownScrollY = 0;
        this._ticking = false;
        this._offset = 100;

        bodyLoaded(() => {
            setTimeout(() => {
                if (typeof locomotive !== "undefined" && !locomotive.scroll.isMobile) {
                    locomotive.on("scroll", (e) => {
                        this.onScroll(e.delta.y);
                    });
                } else {
                    window.addEventListener('scroll', ()=>{
                        this.onScroll(window.scrollY);
                    }, false);
                }
            }, 100);
        })
    }

    disconnect() {
        let self = this;

        window.removeEventListener('scroll', ()=>{
            self.onScroll(window.scrollY);
        }, false);
    }

    onScroll(currentScrollY) {
        if (!this._ticking) {
            requestAnimationFrame(() => {
                if (currentScrollY >= this._offset) {
                    document.documentElement.classList.add("header--not-top");
                    if (currentScrollY > self._latestKnownScrollY) {
                        document.documentElement.classList.remove("header--pinned");
                        document.documentElement.classList.add("header--unpinned");
                    } else {
                        document.documentElement.classList.remove("header--unpinned");
                        document.documentElement.classList.add("header--pinned");
                    }
                } else {
                    document.documentElement.classList.remove("header--not-top", "header--pinned", "header--unpinned");
                }
                this._latestKnownScrollY = currentScrollY;
            });

            this._ticking = false;
        } else {
            this._ticking = true;
        }
    }
});