loadStimulus(document);

application.register("lib", class extends Stimulus.Controller {
    ripple(e) {
        LibRipple(e.currentTarget, e)
    }

    anchor(e) {
        e.preventDefault();
        LibAnchor.action(e.currentTarget)
    }
});

application.register("lib-dialog", class extends Stimulus.Controller {
    connect() {
        let element = this.element;

        if (typeof element.dataset.libDialogOpen !== "undefined") {
            let url = element.getAttribute("data-action-url");

            if (url) {
                LibDialog.action(element, url, () => loadStimulus(document.querySelector(".lib--dialog")) );
            } else {
                LibDialog.show(document.querySelector(element.dataset.libDialogOpen).innerHTML, () => loadStimulus(document.querySelector(".lib--dialog")));
            }
        }
    }

    show(e) {
        let element = e.currentTarget;
        let url = element.getAttribute("data-action-url");

        LibDialog.action(element, url, () => loadStimulus(document.querySelector(".lib--dialog")));
    }

    hide() {
        LibDialog.hide();
    }
});

application.register("lib-tabs", class extends Stimulus.Controller {
    connect() {
        LibTabs(this.element);
    }
});

application.register("lib-ns", class extends Stimulus.Controller {
    connect() {
        if (!('scrollBehavior' in document.documentElement.style)) {
            importScript(cdnjs.seamless, () => seamless.polyfill());
        }

        bodyLoaded(() => LibNativeSlider(this.element.querySelector("[data-lib-ns]"), this.element))
    }
});